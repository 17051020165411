import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  MenuButton,
  TextButton,
} from '@unistorecom/ui';
import { AppRoutes } from 'const';
import cn from 'classnames';

import Logo from '../../Logo';
import Actions from './Actions';
import NavBar from './NavBar';

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { push } = useHistory();
  const handleToggleMenu = () => setMenuOpen(open => !open);
  const closeMenu = () => setMenuOpen(false);
  const handleSignIn = () => push(AppRoutes.SIGN_IN);

  return (
    <div className="Header__MobileMenu">
      <Logo />
      <div className="Header__RightPanel">
        <TextButton
          onClick={handleSignIn}
          hidden={menuOpen}
        >
          {t('sign_in.sign_in_button')}
        </TextButton>
        <div className="Header__VerticalDelimiter" hidden={menuOpen} />
        <MenuButton open={menuOpen} onToggleOpen={handleToggleMenu} />
      </div>
      <div
        className={
          cn(
            'Header__FullScreenMenuWrapper',
            { 'Header__FullScreenMenuWrapper_opened': menuOpen },
          )
        }
      >
        <div className="Header__FullScreenMenu">
          <NavBar onClick={closeMenu} />
          <div className="Header__HorizontalDelimiter"/>
          <Actions onClick={closeMenu} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(MobileMenu);
