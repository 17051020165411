import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader, CookieConsent } from '@unistorecom/ui';
import { useMerchant } from 'store/merchant';
import { useEnvironmentStore } from 'store/environment';
import { AppRoutes } from 'const';
import AuthVerify from 'AuthVerify';
import UnauthorizedLayout from 'components/UnauthorizedLayout';

import './index.scss';
import './i18n';

const SignInPage = React.lazy(() => import('./pages/SignIn'));
const SignUpPage = React.lazy(() => import('./pages/SignUp'));
const PasswordResetPage = React.lazy(() => import('./pages/PasswordReset'));
const NewPasswordPage = React.lazy(() => import('./pages/NewPassword'));
const SettingsPage = React.lazy(() => import('./pages/Settings'));
const NotFoundPage = React.lazy(() => import('./pages/NotFound'));
const ConfirmEmail = React.lazy(() => import('./pages/ConfirmEmail'));
const TermsOfUsePage = React.lazy(() => import('./pages/TermsOfUse'));
const PolicyOfUsePage = React.lazy(() => import('./pages/PolicyOfUse'));

const GOOGLE_ANALYTICS_ID = 'G-6VNRCD8GPN';
const GOOGLE_TAG_MANAGER_ID = 'GTM-NJD9L65K';
// const META_ID = '25467837036148143';
const COOKIE_NAME = '_tracking_consent_dashboard';

const App = () => {
  const { merchant, loading, dispatch } = useMerchant();
  const { environment } = useEnvironmentStore();
  const cookiePolicyUrl = environment?.cookiePolicyUrl ?? '';
  const cookieDomain = environment?.cookiePolicyUrl ? new URL(environment?.cookiePolicyUrl).hostname : '';

  if (loading) return <Loader />;

  const handleUpdateMerchant = () => {
    dispatch('merchant/refetch');
  };

  const authorizedRoutes = (
    <Switch>
      <Route path={AppRoutes.SETTINGS}>
        <SettingsPage merchant={merchant} updateMerchant={handleUpdateMerchant} />
      </Route>
      <Route path={AppRoutes.CONFIRM_EMAIL}>
        <ConfirmEmail />
      </Route>
      <Route path={AppRoutes.TERMS_OF_USE}>
        <TermsOfUsePage />
      </Route>
      <Route path={AppRoutes.POLICY_OF_USE}>
        <PolicyOfUsePage />
      </Route>
      <Redirect from={AppRoutes.SIGN_IN} to={AppRoutes.SETTINGS} />
      <Redirect from={AppRoutes.SIGN_UP} to={AppRoutes.SETTINGS} />
      <Redirect from={AppRoutes.PASSWORD_RESET} to={AppRoutes.SETTINGS} />
      <Redirect from={AppRoutes.NEW_PASSWORD} to={AppRoutes.SETTINGS} />
      <Redirect from={AppRoutes.HOME} to={AppRoutes.SETTINGS} />
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );

  const unauthorizedRoutes = (
    <Switch>
      <Route path={AppRoutes.SIGN_IN}>
        <UnauthorizedLayout>
          <SignInPage />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.SIGN_UP}>
        <UnauthorizedLayout>
          <SignUpPage />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.PASSWORD_RESET}>
        <UnauthorizedLayout>
          <PasswordResetPage />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.NEW_PASSWORD}>
        <UnauthorizedLayout>
          <NewPasswordPage />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.CONFIRM_EMAIL}>
        <UnauthorizedLayout>
          <ConfirmEmail />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.TERMS_OF_USE}>
        <UnauthorizedLayout hasFooter>
          <TermsOfUsePage />
        </UnauthorizedLayout>
      </Route>
      <Route path={AppRoutes.POLICY_OF_USE}>
        <UnauthorizedLayout hasFooter>
          <PolicyOfUsePage />
        </UnauthorizedLayout>
      </Route>
      <Redirect to={AppRoutes.SIGN_IN} />
    </Switch>
  );

  return (
    <Suspense fallback={<Loader />}>
      {merchant ? authorizedRoutes : unauthorizedRoutes}
      <AuthVerify />
      <CookieConsent
        cookieName={COOKIE_NAME}
        linkCookiePolicy={cookiePolicyUrl}
        cookieDomain={cookieDomain}
        config={{
          ga: GOOGLE_ANALYTICS_ID,
          gtm: GOOGLE_TAG_MANAGER_ID,
          // meta: META_ID,
        }}
      />
    </Suspense>
  );
};

export default App;
