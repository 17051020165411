import React from 'react';

import Logo from '../../Logo';
import Actions from './Actions';
// import NavBar from './NavBar';

const DesktopMenu = () => {
  return (
    <div className="Header__DesktopMenu">
      <Logo />
      {/*<NavBar className="Header__NavBar_desktop"/>*/}
      <Actions />
    </div>
  );
};

export default React.memo(DesktopMenu);
