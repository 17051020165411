export const PASSWORD_RESET = `
  mutation PasswordReset ($email: String!) {
    merchantPasswordRecoveryMail(input: { email: $email }) {
      mailIsFound
    }
  }
`;

export const CHECK_PASSWORD_RECOVERY_CODE = `
  mutation CheckPasswordResetCode ($code: String!) {
    merchantPasswordRecoveryCheckCode(input: { code: $code })
  }
`;

export const SET_NEW_PASSWORD = `
  mutation PasswordRecoverySet ($code: String!, $newPassword: String!) {
    merchantPasswordRecoverySet(input: { code: $code, newPassword: $newPassword })
  }
`;
