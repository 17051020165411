import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

// import links from './links';

interface Props {
  className?: string;
  onClick?: () => void;
}

const NavBar = (props: Props) => {
  const { className, onClick } = props;
  // const { t } = useTranslation();
  // const preventClick = (e: SyntheticEvent) => e.stopPropagation();
  const componentClass = cn('Header__NavBar', className);

  return (
    <div className={componentClass} onClick={onClick}>
      <Link
        className={cn('Header__Link', 'Header__Link_type_home')}
        to="/"
      >
        Home
      </Link>
      {/*{links.map(({ name, href }) => (*/}
      {/*  <Link*/}
      {/*    className="Header__Link"*/}
      {/*    key={name}*/}
      {/*    to={href}*/}
      {/*  >*/}
      {/*    {t(name)}*/}
      {/*  </Link>*/}
      {/*))}*/}
      {/*<Link*/}
      {/*  className={cn('Header__Link', 'Header__Link_type_pricing')}*/}
      {/*  to="#"*/}
      {/*  onClick={preventClick}*/}
      {/*>*/}
      {/*  {t('links.pricing')}*/}
      {/*  <span className="Header__TotalFree">*/}
      {/*    {t('totally_free')}*/}
      {/*  </span>*/}
      {/*</Link>*/}
    </div>
  );
};

const areEqual = (prev: Props, next: Props) => prev === next;

export default React.memo(NavBar, areEqual);
