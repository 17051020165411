const error = `
  error {
    code
    fields {
      field
      message
    }
    message
  }
`;

export const GET_TOKENS = `
  query personalAccessTokens {
    personalAccessTokens {
      id
      title
      updatedAt
    }
  }
`;

export const CREATE_TOKEN = `
  mutation personalAccessTokenCreate($title: String!) {
    personalAccessTokenCreate(input: { title: $title }) {
      accessToken {
        id
        title
        updatedAt
        accessToken
      }
      ${error}
    }
  }
`;

export const UPDATE_TOKEN = `
  mutation personalAccessTokenUpdate($id: ID!, $title: String!) {
    personalAccessTokenUpdate(input: { id: $id, title: $title }) {
      accessToken {
        id
        title
        updatedAt
        accessToken
      }
      ${error}
    }
  }
`;

export const DELETE_TOKEN = `
  mutation personalAccessTokenDelete($id: ID!) {
    personalAccessTokenDelete(input: { id: $id }) {
      ${error}
    }
  }
`;
