export enum AppRoutes {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  PASSWORD_RESET = '/password-reset',
  NEW_PASSWORD = '/new-password',
  SETTINGS = '/settings',
  CONFIRM_EMAIL = '/confirm-email',
  TERMS_OF_USE = '/terms-of-use',
  POLICY_OF_USE = '/policy-of-use',
}
