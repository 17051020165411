import { Severity } from 'types';
import { StoreonModule } from 'storeon';
import { useStoreon } from 'storeon/react';
import { Notification } from 'types';

const NOTIFICATIONS_LIMIT = 1;

export interface NotificationState {
  notifications: Notification[];
}

export interface NotificationEvents {
  'notifications/push': Notification,
  'notifications/delete': Notification,
}

export const notificationsModule: StoreonModule<NotificationState, NotificationEvents> = store => {
  store.on('@init', () => ({ notifications: [] }));

  store.on('notifications/push', ({ notifications }, notification) => ({
    notifications: notifications
      .concat(notification)
      .slice(-NOTIFICATIONS_LIMIT),
  }));

  store.on('notifications/delete', ({ notifications }, notification) => ({
    notifications: notifications.filter(({ id }) => id !== notification.id),
  }));
};

export const useNotifications = () => {
  return useStoreon<NotificationState, NotificationEvents>('notifications');
};

export const createNotification = (text: string, severity: Severity) => ({
  id: Date.now(),
  severity,
  text,
});
