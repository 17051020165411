import translationUS from './ditto/components__accounts__base.json';
import translationIN from './ditto/components__accounts__hi-in.json';
import translationMX from './ditto/components__accounts__es-mx.json';
import translationBR from './ditto/components__accounts__pt-br.json';

const translations = {
  enUS: { translation: translationUS.accounts },
  hiIN: { translation: translationIN.accounts },
  esMX: { translation: translationMX.accounts },
  ptBR: { translation: translationBR.accounts },
};

export default translations;
