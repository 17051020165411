import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ClientContext } from 'graphql-hooks';
import { StoreContext } from 'storeon/react';
import { useServerErrorStatus } from '@unistorecom/ui';
import ServerErrorContext from 'ServerErrorContext';
import store from 'store';
import { client } from 'api';

import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);

const Wrapper = () => {
  const { isInternalServerError, client: clientWithErrorHandler, reload } = useServerErrorStatus({ client });

  return (
    <ClientContext.Provider value={clientWithErrorHandler}>
      <StoreContext.Provider value={store}>
        <ServerErrorContext.Provider value={{ isInternalServerError, reload }}>
          <App />
        </ServerErrorContext.Provider>
      </StoreContext.Provider>
    </ClientContext.Provider >
  );
};

root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18n}>
        <Wrapper />
      </I18nextProvider>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
