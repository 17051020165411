import { createStoreon } from 'storeon';

import { NotificationEvents, notificationsModule, NotificationState } from './notifications';
import { merchantModule, MerchantState, MerchantEvents } from './merchant';
import { EnvironmentEvents, EnvironmentState, environmentModule } from './environment';

type State = NotificationState & MerchantState & EnvironmentState;

type Events = NotificationEvents & MerchantEvents & EnvironmentEvents;

export default createStoreon<State, Events>([
  notificationsModule,
  merchantModule,
  environmentModule,
]);
