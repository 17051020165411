import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './translations';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      'en_US': translations.enUS,
      'hi_IN': translations.hiIN,
      'es_MX': translations.esMX,
      'pt_BR': translations.ptBR,
    },
    lng: 'en_US',
    fallbackLng: 'en_US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
