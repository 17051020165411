import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'const';
import { useEnvironmentStore } from 'store/environment';

import Header from '../Header';
import './style.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  hasFooter?: boolean;
}

const UnauthorizedLayout = (props: Props) => {
  const { className, hasFooter, children } = props;
  const { t } = useTranslation();
  const { environment } = useEnvironmentStore();
  const cookiePolicyUrl = environment?.cookiePolicyUrl ?? '';
  const componentClass = cn(className, 'UnauthorizedLayout');
  const currentYear = new Date().getFullYear();

  const links = [
    {
      path: `/#${AppRoutes.TERMS_OF_USE}`,
      title: t('terms_of_use'),
    },
    {
      path: cookiePolicyUrl,
      title: t('cookie_policy'),
    },
  ];

  return (
    <div className={componentClass}>
      <Header />
      <main className="UnauthorizedLayout__Main">
        {children}
      </main>
      {hasFooter && (
        <footer className="UnauthorizedLayout__Footer">
          &copy; {t('copyright', { year: currentYear })}
          <div className="UnauthorizedLayout__Routes">
            {links.map(link => (
              <a
                key={link.path}
                className="UnauthorizedLayout__Link"
                href={link.path}
                target="_blank"
                rel="noreferrer"
              >
                {link.title}
              </a>
            ))}
          </div>
        </footer>
      )}
    </div>
  );
};

const areEqual = (prev: Props, next: Props) => prev === next;

export default React.memo(UnauthorizedLayout, areEqual);
