import React from 'react';
import cn from 'classnames';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';
import './style.scss';

interface Props {
  className?: string;
}

const Header = (props: Props) => {
  const { className } = props;
  const componentClass = cn(className, 'Header');

  return (
    <div className={componentClass}>
      <MobileMenu />
      <DesktopMenu />
    </div>
  );
};

const areEqual = (prev: Props, next: Props) => prev === next;

export default React.memo(Header, areEqual);
