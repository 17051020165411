import { client, GET_ENVIRONMENT } from 'api';
import { StoreonModule } from 'storeon';
import { useStoreon } from 'storeon/react';
import { Environment } from 'types';

export interface EnvironmentState {
  environment: Environment | null;
}

export interface EnvironmentEvents {
  'environment/set': EnvironmentState;
}

interface QueryResult {
  environment: Environment;
}

export const getEnvironment = async () => {
  try {
    const { data } = await client.request<QueryResult>({
      query: GET_ENVIRONMENT,
    });

    return data?.environment;
  } catch (err) {
    console.log(err);
  }
};

export const environmentModule: StoreonModule<EnvironmentState, EnvironmentEvents> = store => {
  store.on('@init', async () => {
    store.dispatch('environment/set', {
      environment: null,
    });

    const environment = await getEnvironment() ?? null;

    store.dispatch('environment/set', {
      environment,
    });
  });

  store.on('environment/set', (_, newState) => newState);
};

export const useEnvironmentStore = () => {
  return useStoreon<EnvironmentState, EnvironmentEvents>('environment');
};
