import React from 'react';
import { useHistory } from 'react-router-dom';

const AuthVerify = () => {
  const history = useHistory();

  history.listen(async () => {
    const isAuth = localStorage.getItem('isAuth') === '1';

    if (!isAuth) {
      window.location.reload();
    }
  });

  return <div />;
};

export default AuthVerify;
