import { GraphQLClient } from 'graphql-hooks';
import { API_URL } from 'const';

const isDevEnv = process.env.NODE_ENV === 'development';

const client = new GraphQLClient({
  url: `${API_URL}/graphql`,
  fetchOptions: {
    credentials: 'include',
  },
  logErrors: isDevEnv,
});

export default client;
