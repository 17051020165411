export const API_URL = process.env.REACT_APP_API_URL;

export const LANGUAGES: { [key: string]: string } = {
  'en_US': 'English (US)',
  'hi_IN': 'हिन्दी (भारत गणराज्य)',
  'es_MX': 'Español (México)',
  'pt_BR': 'Português (Brasil)',
} as const;

export const CONTACT_US = 'support@unistore.com';
